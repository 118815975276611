import { components } from '@/lib/helpers.js'

//
// Fonts
//
import '@fontsource-variable/saira'

//
// Styles
//
import '../css/style.css'

//
// Initialize components
//
components({
    header: import('@/components/header.js'),
    'embed-video': import('@/components/embed/video.js'),
    video: import('@/components/video.js'),
    courses: import('@/components/courses.js'),
    parts: import('@/components/parts.js'),
    occasions: import('@/components/occasions.js'),
    dropdown: import('@/components/dropdown.js'),
    select: import('@/components/select.js'),
})
